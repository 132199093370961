import React, { useState, useEffect } from 'react';
import Pic1 from '../assets/img/3.png';
import Pic2 from '../assets/img/4.png';
import Pic3 from '../assets/img/5.png';
import Pic4 from '../assets/img/6.png';
import Pic5 from '../assets/img/7.png';
import '../App.css';

const ComingSoon = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [Pic1, Pic2, Pic3, Pic4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImageIndex, images.length]);

  const imageStyles = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    transition: 'background-image 1s ease-in-out'
  };

  return (
    <div className="coming-soon-container">
      <div className="background-image" style={imageStyles}></div>
      <img className="globe" src={Pic5} alt="globe" />
    </div>
  );
};

export default ComingSoon;
